.footer_site{
  background-color: lighten(theme-color('dark'), 10);
  color: white;
  font-family: $lato_bold;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  .brevo__footer {
    font-family: 'lato';
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #F7F7F6;
    .container{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .brevo_title{
      h2{
        font-family: $font_title !important;
        font-size: 48px !important;
        font-weight: 400;
        color: theme-color('primary');
      }
    }
    form{
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      label {
        color: #212529;

        @include media-breakpoint-up(lg){
          margin-bottom: 0;
        }

        @include media-breakpoint-down(sm){
          margin-bottom: 1rem;
        }
      }

      .form_email{
        margin-bottom: 1rem;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        label{
          margin-right: 1rem;
        }
      }
      .form_rgpd_optin{
        font-size: 14px;

        @include media-breakpoint-down(md){
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;

          input{
            margin-top: .2rem;
            margin-right: .5rem;
          }
        }

      }

      #id_email{
        margin-right: 1rem;
        background: #FBFAFF;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        border: 1px solid #ced4da;
        height: 35px;
        padding: 0.375rem 0.75rem;
        width: 300px;

        @include media-breakpoint-down(sm){
          margin-bottom: 1rem;
        }

        &:focus{
          color: #495057;
          background-color: #ffffff;
          border-color: #de8b8a;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(182, 52, 51, 0.25);
        }
      }
      .info-rgpd{
        color: #212529;
        font-size: 14px;
        margin-top: 0px;
      }
    }
  }
  .top__footer{
    padding-top: 4rem;
  }
  .top__footer .nav{
    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
    }
    .nav-link{
      padding: 0.2rem 0rem;
    }
  }
  address{
    margin-top: 20px;
  }
  .languages{
    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
    }
    .dropdown{
      a.btn-toggle{

        background-color: #242323;
        padding: 10px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        min-width: 200px;
      }

    }
  }
  .icn{
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    text-align: center;
  }
  .logo{
    img{
      max-width: 160px;
      height: auto;
    }
  }
  ul a{
    color: white;
  }
  .legal{
    color: white;
    margin-top: 1rem;
    font-size: 13px;
    background-color: theme-color('dark');
    font-family: $lato_regular;
    a{
      color: white;
      font-size: 13px;
    }
  }
  .dropdown-menu {
    a{
      color: inherit;
    }
  }
}

.social_links{
  @include media-breakpoint-down(sm) {

    text-align: center;
  }
  a{
    display: inline-flex;
    width: 40px;
    color: white;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: theme-color('dark');
    transition: all .3s ease;
    &:hover{
      text-decoration: none;
      background-color: theme-color('primary');
    }
  }
}

.flags-sprite{
  background-image: url(../img/spritesheet_flags.png);
  display: inline-block;
  width: 28px;
  height: 25px;
  &.fr{
    background-position: -36px 62px;
  }
  &.en{
    background-position: 66px 0px;
  }
  &.it{
    background-position: 32px 0px;
  }
  &.de{
    background-position: 0 0;
  }
}


