body:not(#id_error_page):not(#id_legalnotice) {
  header.header{
    .logo_vnv{
      width: 80px;
      height: auto;
    }
    &.header-scroll{
      @include media-breakpoint-up(lg) {
        background-image:none;
        background-color: #222;
        position: fixed !important;
        animation: 1s translate;
        z-index: 99999;
        top: 0;
        left: 0;
        right: 0;
        .nav-link{
          font-size: 17px !important;
          color: white;
        }
        .logo_vnv{
          width: 80px;
          height: auto;
        }
        .nav_sub{
          li{
            .nav-link{
              font-size: 17px !important;
            }
          }
        }
        #id_main_logo{
          img{
            max-width: 100px;
          }
        }
        .top_bar{
          background-color: black !important;
          margin-bottom: 0;
          *{
            font-size: 14px !important;
          }
        }
        .navbar{
          padding: 0 10px;
          .navbar-nav{
            padding-bottom: 0 !important;
            border-bottom: 0  !important;
          }
        }
        .active,  .ancestor{
          a.nav-link{
            &::before{
              bottom: -2px;
            }
          }
        }
      }
    }
  }
}

.link-group{
  display: flex;
  align-items: center;
  .logo-xs{
    width: 50px;
    height: auto;
  }
}
#id_error_page, id_legalnotice{
  .top_bar{
    display: none;
  }
  main{
    background-image: linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,normal;
  }
}
.navbar-toggler{
  background-color: theme-color('secondary');
}header.header{
  @include media-breakpoint-down(md) {
    background-color: theme-color('dark');
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;

  }
  &.header_subsite{
    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(180deg, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0.00) 100%);
    }

  }
  .btn--search{
    background-color: transparent;
    border:0;
    color: white;
    padding: 0.5rem 1rem;
  }

  .top_bar{
    height: 40px;
    @include media-breakpoint-up(lg) {
      background-color: theme-color('dark');;
      margin-bottom: .2rem;
    }
    text-align: right;
    display: flex;
    justify-content: flex-end;
    *{
      font-size: 13px !important;
    }
    a.nav-link{
      color: white;
      font-size: 14px;
    }
    @include media-breakpoint-down(sm) {
      background-color: darken(theme-color('dark'), 3) !important;
      border-bottom: 1px solid #000;
      a.nav-link{
        padding: 0.4rem;
      }
    }
  }

  .sites{
    a{
      color: white !important;
    }
  }
  .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
  nav{
    .nav{
      display: flex;
      list-style: none;
      padding-left: 0;
      >li{
        padding: 1rem;
        &:not(:first-child){
          margin-left: 20px;
        }
        &:not(:last-child){
          padding-right: 0;
        }
        a{
          color: theme-color('dark');
        }
      }
    }
  }
  .dropdown-menu{
    background-color: #0E0E0E;
    color: white;
    .dropdown-item{
      color: white;
      &:hover{
        background-color: transparent;
        color: theme-color('primary');
      }
    }
  }
  .logo_xs{
    max-width: 60px;
    display: inline-block;
    margin-right: 1rem;
  }
  .nav_sub{
    li{
      margin-left: 1rem;
      margin-right: 1rem;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
      .nav-link{
        @include media-breakpoint-up(lg) {

          font-size: 20px !important;
        }
      }
    }
  }

}
#vnvnav{
  @include media-breakpoint-up(xl) {

    .nav-item {
      font-size: 18px;
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
    }
    .dropdown-menu {
      .nav-item{
        margin-left: 0;
      }
    }
  }
}
#id_index{
  @include media-breakpoint-up(lg) {

    header.header{
      background-image: none;
      .nav-item {
        font-size: 18px;
      }

      .navbar{
        border-bottom: 1px solid #eee;
      }
      &.header-scroll{
        left: 0;
        right: 0;
        background-color: white;
        border-bottom: 1px solid #eee;
        .navbar{
          border-bottom: 0;
        }

      }
    }
  }
}
#id_main_logo{
  img{
    @include media-breakpoint-up(sm) {
      max-width: 180px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100px;
    }
    height: auto;
  }
}
#id_ticketing{
  #id_main_logo img{
    max-width: 180px !important;
  }
  .back-link{
    color: white;
  }
}
#id_content, #id_sub_index, #id_ticketing{
  header.header{
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      z-index: 1;
      left: 0;
      right: 0;

    }
    .top_bar{
      background-color: transparent;
    }
    .nav-link{
      @include media-breakpoint-up(lg) {

        font-size: 18px;
      }
      font-family: $lato_bold;
    }
    .navbar-nav{
      border-bottom: 1px solid #5D5D5D;
      padding-bottom: 1rem;
      .nav-item{
        a.nav-link{
          color: white;
        }

      }
    }
  }
}
.active,  .ancestor{
  a.nav-link{
    position: relative;
    &::before{
      @include media-breakpoint-up(lg) {

        width: 40%;
        height: 4px;
        content: "";
        display: block;
        position: absolute;
        bottom: -19px;
        left: 50%;
        margin-left: -20%;
      }
    }
  }
}

i.icn{
  @include media-breakpoint-up(lg) {
    display: block;
    margin: 0 auto ;
  }
  &.discover{
    @include retina-sprite($sprite_explore-group);
  }
  &.prepare{
    @include retina-sprite($sprite_map-group);
  }
  &.tickets{
    @include retina-sprite($sprite_tickets-group);
    margin-bottom: 4px;
  }
}


.vnv_dropdown{
  a{
    color: white;
  }
  li .nav-link{
    font-size: 16px !important;
    display: flex;
    align-items: center;
  }
  li .nav-link-subtitle{
    font-size: 14px;
    font-family: $lato_light;
  }
  .dropdown-menu{
    padding: 0;
    width: 100%;
  }
  .nav-item{
    padding: .5rem 0;

    .icon {
      margin-right: 1rem;
    }

    &:last-child {
      border-top: solid 1px #333;
    }
  }


  li{
    a{
      transition: all .3s ease;
    }
    .icon{
      display: block;
      width: 46px;
      height: 42px;
      background-image: url(../img/sprite-snat.png);
      zoom: .8;
      &.choranche{
        background-position: 0px 49px;
        height: 49px;
      }
      &.jardin{
        background-position: 0px 0px;
      }
      &.bateau{
        background-position: 90px 0px;
      }
      &.thais{
        background-position: 35px 0px;
        margin-right: 27px;
        width: 35px;
      }
    }
    &:hover{
      &.bateau_li{
        a{
          color: theme-color('bateau');
        }
      }
      &.choranche_li{
        a{
          color: theme-color('choranche');
        }
      }
      &.jardin_li{
        a{
          color: theme-color('jardin');
        }
      }
      &.thais_li{
        a{
          color: theme-color('primary');
        }
      }
      .icon.choranche{
        background-position: -58px 49px;
      }
      .icon.bateau{
        background-position: 90px 50px;
      }
      .icon.thais{
        background-position: 35px 46px;
      }
      .icon.jardin{
        background-position: -65px 00px;
      }
    }
  }
}


body{
  &.choranche{
    header{
      .nav_sub{
        li{
          &.active, &:hover, &.ancestor{
            >a{
              color: theme-color('choranche') !important;
              &::before{
                background-color: theme-color('choranche');
              }
            }
            .icn {
              &.discover{
                @include retina-sprite($sprite_explore_hover_choranche-group);
              }
              &.prepare{
                @include retina-sprite($sprite_map_hover_choranche-group);
              }
              &.tickets{
                @include retina-sprite($sprite_tickets_hover_choranche-group);
              }
            }
          }
        }
        .dropdown{
          a{
            &:hover{
              color: theme-color('choranche');
            }
          }
        }
      }
    }
  }
  &.jardin{
    header{
      .nav_sub{
        li{
          &.active, &:hover, &.ancestor{
            >a{
              color: theme-color('jardin') !important;
              &::before{
                background-color: theme-color('jardin');
              }
            }
            .icn {
              &.discover{
                @include retina-sprite($sprite_explore_hover_jardin-group);
              }
              &.prepare{
                @include retina-sprite($sprite_map_hover_jardin-group);
              }
              &.tickets{
                @include retina-sprite($sprite_tickets_hover_jardin-group);
              }
            }
          }
        }
        .dropdown{
          a{
            &:hover{
              color: theme-color('jardin');
            }
          }
        }
      }
    }
  }
  &.bateau{
    header{
      .nav_sub{
        li{
          &.active, &:hover, &.ancestor{
            >a{
              color: theme-color('bateau') !important;
              &::before{
                background-color: theme-color('bateau');
              }
            }
            .icn {
              &.discover{
                @include retina-sprite($sprite_explore_hover_bateau-group);
              }
              &.prepare{
                @include retina-sprite($sprite_map_hover_bateau-group);
              }
              &.tickets{
                @include retina-sprite($sprite_tickets_hover_bateau-group);
              }
            }
          }
        }
        .dropdown{
          a{
            &:hover{
              color: theme-color('bateau');
            }
          }
        }
      }
    }
  }
  &.thais{
    header{
      .nav_sub{
        li{
          &.active, &:hover, &.ancestor{
            >a{
              color: theme-color('thais') !important;
              &::before{
                background-color: theme-color('thais');
              }
            }
            .icn {
              &.discover{
                @include retina-sprite($sprite_explore_hover_thais-group);
              }
              &.prepare{
                @include retina-sprite($sprite_map_hover_thais-group);
              }
              &.tickets{
                @include retina-sprite($sprite_tickets_hover_thais-group);
              }
            }
          }
        }
        .dropdown{
          a{
            &:hover{
              color: theme-color('thais');
            }
          }
        }
      }
    }
  }
}


@keyframes translate {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}



/* ==========================================================================
   Search ui
   ========================================================================== */
.btn--search {
  font-size: 1.5em;
}

.btn--hidden {
  pointer-events: none;
  opacity: 0;
}
.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}


  /* Reset Search Input */

.search__input {
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}

.search__input:focus {
  outline: none;
}


/* Layout for search container */
.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.search {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.search::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
}

.btn--search-close {
  font-size: 1.6rem;
  position: absolute;
  top: 0em;
  right: 0em;
  display: none;
  svg{
    fill: theme-color('white');
    width: 40px;
    height: 25px;
  }
}

.btn--search-close {
  display: block;
}

.search__form {
  @include media-breakpoint-up(lg) {
    margin: 5em 0;
  }
}

.search__input {
  font-family: inherit;
  @include media-breakpoint-up(lg) {

    font-size: 60px;
  }
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.05em 01rem;
  color: theme-color('choranche');
  border-bottom: 3px solid;
}

.search__input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
  color: #fff;
}

.search__input::-moz-placeholder {
  opacity: 1;
	/* Mozilla Firefox 19+ */
  color: #fff;
}

.search__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
  color: #fff;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search__input::-ms-clear {
  display: none;
}

.search__info {
  font-size: 90%;
  font-weight: bold;
  display: block;
  width: 75%;
  margin: 0 auto;
  padding: 0.85em 0;
  text-align: right;
  color: theme-color('primary');;
}

.search__related {
  display: flex;
  width: 75%;
  text-align: left;
  pointer-events: none;
}

.search__suggestion {
  width: 50%;
}

.search__suggestion:first-child {
  padding: 0 2em 0 0;
}

.search__suggestion:last-child {
  padding: 0 0 0 2em;
}

.search__suggestion h3 {
  font-size: 1.35em;
  margin: 0;
}

.search__suggestion h3::before {
  content: '\21FE';
  display: inline-block;
  padding: 0 0.5em 0 0;
}

.search__suggestion p {
  font-size: 1.15em;
  line-height: 1.4;
  margin: 0.75em 0 0 0;
}

/************************/
/* Transitions 			*/
/************************/

body > main {
  position: relative;
  transition: transform 0.5s;
}

.main-wrap--move {
	//transform: scale3d(0.7,0.7,1);
}

.main-wrap--move .btn--search {
  pointer-events: none;
  opacity: 0;
}

.search {
  pointer-events: none;
}

.search--open {
  pointer-events: auto;
}

.search::before {
  opacity: 0;
  transition: opacity 0.5s;
}

.search--open::before {
  opacity: 1;
}

/* Close button */
.btn--search-close {
  opacity: 0;
  transition: opacity 0.5s;
  background-color: transparent;
  border:0;
  color: theme-color('choranche');
  font-size: 30px;
  svg{
    fill: theme-color('choranche');
    width: 70px;
    height: 40px;
  }
}

.search--open .btn--search-close {
  opacity: 1;
}

/* Search form with input and description */

.search__suggestion,
.search__info {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  transition: opacity 0.4s, transform 0.4s;
}

.search--open .search__suggestion,
.search--open .search__info {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.search--open .search__suggestion:first-child {
  transition-delay: 0.15s;
}

.search--open .search__suggestion:nth-child(2) {
  transition-delay: 0.2s;
}

.search__input {
  transform: scale3d(0,1,1);
  transform-origin: 0% 50%;
  transition: transform 0.3s;
}

.search--open .search__input {
  transform: scale3d(1,1,1);
  transition-duration: 0.5s;
}

@media screen and (max-width: 40em) {
  .btn--search-close {
    font-size: 1.25em;
  }
  .search__suggestion {
    font-size: 80%;
    width: 100%;
  }
  .search__suggestion:nth-child(2),
  .search__suggestion:nth-child(3) {
    display: none;
  }
}


.ticketing_nav{
  img{
    max-width: 80px !important;
  }
}
#id_main_logo{

  img.logo_vnv{
    @include media-breakpoint-down(lg) {
      max-width: 80px !important;
      height: auto;
    }
  }
}



/* ==========================================================================
   rwd
   ========================================================================== */
// @include media-breakpoint-only(lg) {
//   header.header_subsite{
//     position: relative !important;
//     background-color: #222;
//     .navbar-collapse{
//       display: flex !important;
//       background-color: theme-color('dark');
//       position: absolute;
//       left: 0;
//       right: 0;
//       bottom: 100px;
//       .navbar-nav{
//         flex-direction: row;
//       }
//       *{
//         color: white;
//       }
//       &.show{

//       }
//     }
//   }
// }
@include media-breakpoint-only(lg) {
  header.header_subsite{
    background-color: #222;
    .navbar-brand{
      max-width: 120px;
      img{
        max-width: 100% !important;
        height: auto;
      }
    }
    .navbar-nav{
      border-bottom: 0 !important;
      &.nav_sub{
        li{

          .nav-link{
            font-size: 90% !important;
          }
        }
      }
    }
    .vnv_dropdown{
      .nav-link{
        font-size: 90% !important;
      }
    }
  }
}
@include media-breakpoint-down(md) {

  .navbar-collapse{
    .navbar-nav{
      border-bottom: 0 !important;
    }
    &.show{
      background-color: theme-color('dark');

      *{
        color: white;
      }

    }
    .dropdown-menu.show{
      border-left: 1px solid #eee;
      border-radius: 0;
      background-color: transparent;
    }
    .icn{

      display: inline-block;
      position: absolute;
      left: 0;

    }
    .nav_sub .nav-item {
      >a.nav-link{
        display: flex;
        position: relative;
        align-items: center;
        padding-left: 60px;
        font-size: 16px !important;
      }
    }
    .logo_xs{
      width: 40px;
    }
  }


  #header.collapse{
    display: block;
    overflow-x: hidden;
    width: 100%;
    .navbar{
      overflow: hidden;
      max-width: 100%;
    }
    &.show{

      *{
        color: white;
      }
      .navbar-collapse.collapse{
        display: block;
      }
    }

  }
  #vnvnav{
    padding-top: 3rem;
  }
  .vnv_dropdown{
    .dropdown-menu{
      position: static;
      padding-left: 30px;
      border-left: 0 !important;
      li{
        border-left: 1px solid #eee;
      }
      a.nav-link{
        font-size: 14px !important;
      }
    }
  }
}


body:not(.choranche):not(.bateau):not(.thais):not(.jardin) {
  @include media-breakpoint-down(sm) {

    #header.header{
      .navbar{
        padding: 10px 0;
        #id_main_logo img.logo_vnv{
          width: 60px;
          height: auto;
        }
      }
    }
  }
}
