.djangocms-faq-results {
  padding: 1rem;
  background-color: darken(theme-color('bg-light'), 1);
  border-radius: 10px;
  border: 1px solid darken(white, 10);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -7px;
  position: relative;
  padding-top: 2rem;
}

.djangocms-faq-results-list {
  padding-left: 0em;
  list-style: none;
  margin-top: 10px;
  li {
    padding: 12px 1px;
    position: relative;
    transition: all .3s ease;
    &:not(:last-child){
      border-bottom: 1px solid #e3e3e3;
    }
    a {
      transition: all .3s ease;
      display: block;
      text-decoration: none;
      color: inherit;
      font-size: 0.9rem;
    }
    &:hover{
      transform: translateX(10px);
      a{
        color: theme-color('primary');
        transform: translateX(10px);
      }
    }
  }
}

.djangocms-faq-results-title {
  font-size: 1.2em;
  color: theme-color('primary');
  font-family: $lato_bold;
}
.djangocms_faq_question:target{
  padding: 20px;
  border: 1px solid darken(theme-color('choranche'), 2);
  background-color: rgba(theme-color('choranche'),.2);
  .djangocms_faq_child{
    background-color: transparent;
    border-top: 1px solid darken(theme-color('choranche'), 2);
    color: theme-color('dark');
  }
}


.djangocms_faq_results:not(:empty){
  padding-left: 0;
  list-style: none;
  li:first-child{
    margin-top: 30px;
  }
  li:last-child{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;

  }
  a{
    transition: all .3s ease;
    font-family: $lato_bold;
    display: block;
    position: relative;
    margin-bottom: 10px;
    &::before{
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f054";
      font-size: 14px;
      margin-right: 10px;
    }
    &:hover{
      text-decoration: none;
      color: #222 !important;
      .djangocms_faq_result_keywords{
        box-shadow: inset 0 -15px 0 0 rgba(#1584c4, 0.2);
      }
    }
  }

  .djangocms_faq_result_keywords{
    display: inline-block;
    margin-left: 10px;
    opacity: .8;
    text-decoration-color: rgba(21, 132, 196, 0.2);
    text-decoration-style: normal;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    box-shadow: inset 0 -5px 0 0 rgba(#1584c4, 0.2);
    transition: all .3s ease;
  }
}
.djangocms_faq_keyword{
  text-decoration-color: rgba(21, 132, 196, 0.2);
  text-decoration-style: normal;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  box-shadow: inset 0 -5px 0 0 rgba(#1584c4, 0.2);
  transition: all .3s ease;
  display: inline-block;
}
