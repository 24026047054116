
body.thais{
  .navbar-toggler{
    background-color: theme-color('thais');
  }
  .btn{
    &.btn-primary{
      background-color: theme-color('thais');
      border-color: theme-color('thais');
      &:hover{
        background-color: darken(theme-color('thais'),5);
      }
    }
  }
  .content{
    a:not(.btn){
      color: theme-color('thais');
    }
  }

  .breadcrumb-item {
    a{
      color: theme-color('thais');
    }
  }
  .icn{
    color: theme-color('thais');
  }
  header.header{
    .navbar-nav.nav_sub{
      .nav-item{
        &.active,  &.ancestor{
          a.nav-link{
            color: theme-color('thais') !important;
          }
        }
      }

    }
  }

  .events .carousel__img::after,
  .events .carousel__img::before{
    background-image: linear-gradient(
      180deg
      , #F7A501 0%, #e0ad46 100%);
  }
}
