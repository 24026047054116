.teaser {
  > div {
    display: grid;
    @include media-breakpoint-up(lg) {

      grid-template-columns: 100px 1fr;
    }
    align-items: center;
    padding: 1rem;
    background: rgba(200,208,24,0.19);
    box-shadow: 0 20px 40px 0 rgba(232,232,232,0.50);
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &.blue {
      background: rgba(38,186,216,0.18);
    }

    .teaser-image {
      background-color: white;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-radius: 50px;
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        transform: translateX(-50px);
      }
      @include media-breakpoint-down(lg) {
        margin: 10px auto;
      }

      img {
        max-width: 60%;
        height: auto;
      }
    }

    .teaser-content {
      @include media-breakpoint-down(lg) {
        text-align: center;
      }
      p{
        margin-bottom: 0;
      }
    }
  }
}

.teaser-text-overlay {
  position: relative;

  > div {
    position: relative;
  }

  img {
    max-width: 100%;
    min-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .menu-content {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
    color: white;
    font-family: $lato_black;
    border-radius: 10px;

    .title {
      &::after {
        content: "";
        width: 30px;
        height: 4px;
        background-color: theme-color('primary');
        display: block;
        margin-top: .5rem;
      }
    }
  }
}

.teaser-text-after {
  img {
    max-width: 100%;
    min-width: 100%;
    height: auto;
  }
}
