html {
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}
html,
body {
  height: 100%;
}
body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

}
.btn{
  transition: all .3s ease;
  &:hover{
    transform: scale(1.1);
  }
}
.breadcrumb{
  background-color: transparent;
  min-width: 100%;
}
.header{
  .navbar{
    @include media-breakpoint-down(sm) {
      min-height: 80px;
    }
  }
}
main {
  flex: 1 0 auto;
  @include media-breakpoint-down(sm) {
    margin-top: 120px;
  }
  .container-fluid, .container{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.scroll-margin{
  @include media-breakpoint-up(lg) {

    .header-scroll + main{
      padding-top: 200px;
    }
  }
}

.bg-left{
  background-image: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 37%, #F2F2F2 37%, #F2F2F2 100%);
}
.bg-right{
  background-image: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 37%, #F2F2F2 37%, #F2F2F2 100%);
}

.bg-green{
  background: #FEFFEB;
}
.bg-blue{
  background: #F4FBFF;
}
.breadcrumb{
  li+li{
    padding-left: 0.5rem;
    &:before{
      float: left;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/";
    }
  }
}
.legalnotice-list{
  counter-reset: counter;
  padding-left: 0;
  h2{
    font-size: 18px;
    color: theme-color('primary');
  }
  >li{
    counter-increment: counter;
    text-align: left;
    margin-bottom: .5rem;
    list-style: none;
    padding: 1rem;
    padding-left: 50px;
    position: relative;
    border: 1px solid #eee;
    &:nth-child(odd){
      background-color: #f5F5F5;
    }
    &::before{
      content: counter(counter);
      font-family: $font_title;
      font-size: 4rem;
      line-height: 1;
      position: absolute;
      opacity: .2;
      left:10px;
      top: 10px;
    }
  }
}


#id_legalnotice, #id_error_page, #id_error_404{
  h1{
    font-family: $lato_black;
    margin-bottom: 2rem;
    font-size: 30px;
  }
  body > main {
    padding-top: 250px;
  }
  header.header{
    background-image: none;
    border-bottom: 1px solid #eee;
    &:not(.header-scroll) {
      position: static;
      animation: none;
    }
    a.nav-link{
      color: theme-color('dark') !important;
    }
  }
  main, .main_error{
    display: flex;
    justify-content: center;
  }
}

.main_error{
  .display-1{
    font-family: $font_title;
    font-size: 10rem;
    opacity: .1;
  }
  .message{
    padding: 1rem;
  }
}
.main_error{
  text-align: center;
  .errorimg{
    max-width: 500px;
    mix-blend-mode: multiply;
    height: auto;
  }
}
#id_error_page{
  p{
    max-width: 600px;
    margin: 10px auto;
  }
}
@include media-breakpoint-only(md) {
  header + main{
    padding-top: 140px;
  }
}

.crt-social-icon{
  svg{
    color: white !important;
    width: 30px !important;
    max-height: 30px !important;
  }
}

video {
  width: 100%;
}

blockquote p{
  position: relative;

  &::first-letter{
    font-size: 4rem;
    color: #B63433;
    font-family: $font_title;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    height: 18px;
    width: 30px;
    background-repeat: no-repeat;
  }
  &::before {
    background-image: url(../img/quote.png);
    left: -25px;
  }
  &:after {
    background-image: url(../img/quote-c.png);
    margin-left: 10px;
    bottom: 0;
  }

}