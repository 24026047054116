$fa-font-path: "../fonts/fontawesome";
$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";

$theme-colors: (
  "primary": #B63433,
  "secondary": #5F5D70,
  "bateau":#6E7596,
  "bateau_secondary":#CD1D1A,
  "choranche":#C9D202,
  "choranche_secondary":#67ABAC,
  "jardin":#77B1B1,
  "jardin-secondary":#A54D25,
  "thais":#34A8B8,
  "thais_secondary":#F7A501,
  "dark": #161616,
  "bg-light": #f7f7f7,
  "bg-form": #FBFAFF,
  "green": #FEFFEB
);


@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + ".ttf") format("truetype");
    src: url($file-name + ".woff2") format("woff2");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

//Usage
//@include font-face('smitten', '/fonts/gotham');

@include font-face("smithen", "../fonts/smithen/smithen", "400");
@include font-face("lato", "../fonts/lato/Lato-Regular/Lato-Regular", "400");
@include font-face("lato_black", "../fonts/lato/Lato-Black/Lato-Black", "900");
@include font-face("lato_bold", "../fonts/lato/Lato-Bold/Lato-Bold", "700");
@include font-face("lato_light", "../fonts/lato/Lato-Light/Lato-Light", "300");

$font_title: "smithen";
$lato_regular: "lato";
$lato_black: "lato_black";
$lato_bold: "lato_bold";
$lato_light: "lato_light";
$font-family-sans-serif: $lato_regular, Arial, sans-serif;
