.fobi-form{
  display: flex;
  margin-bottom: 3rem;
  gap: 1rem;
  flex-wrap: wrap;
  input, textarea{
    background-color: theme-color('bg-form');
    box-shadow: 0 2px 11px 0 rgba(0,0,0,0.15);
    border-radius: 10px;
  }
  .form-button-wrapper{
    text-align: right;
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 2rem;
    margin-top: 20px;
  }
  .form-group{
    @include media-breakpoint-up(md) {
      flex: 0 1 49%;
    }
    @include media-breakpoint-down(md) {
      flex: 1 0 100%;
    }
    >label{
      font-weight: bold;
    }
  }

  @include media-breakpoint-up(lg) {
    .help ,.form-textarea-wrapper, .form-element-wrapper-vous_souhaitez_reserver, .form-element-wrapper-objet_de_votre_demande, .form-element-wrapper-votre_message, .form-button-wrapper, .form-required-field-message-wrapper, .form-element-wrapper-vous_etes{
      min-width: 100%;
      p{
        margin-bottom: 0;
      }
    }

  }
  .help-block{
    margin-bottom: 10px;
    font-size: 12px;
    font-style: italic;
    color: darken(white, 60);
  }
}
div[class^="form-element-wrapper"],
div[class*="form-element-wrapper"]  {
  margin: 0;
  margin-bottom: 20px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
  input, textarea, select{
    background: #FBFAFF;
    box-shadow: 0 2px 11px 0 rgba(0,0,0,0.15);
    border-radius: 10px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  select{
    height: 50px;
  }
  select{
    appearance: none;
  }
}

.form-required-field-message-wrapper{
  @extend .alert;
  @extend .alert-info
}

.form-element-wrapper-vous_etes, .form-element-wrapper-vous_souhaitez_reserver, .form-element-wrapper-visiter_choranche{
  >div{
    @include media-breakpoint-up(lg) {
      columns: 2;
    }

  }
}

.form-select-wrapper{
  >div{
    position: relative;
    &::after{
      position: absolute;
      right: 10px;
      top: 10px;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f078";
      pointer-events: none;
    }
  }
}
.form-textarea-wrapper{
  padding-top: 10px;

  p{
    font-size: 18px;
    border-bottom: 1px solid #eee;
  }
}


div[class*="form-element-wrapper-content_text"]{
  background-color: #f5F5f5;
  padding: 1rem;
  margin-top: 2rem !important;
  min-width: 100%;
}

.form-element-wrapper-nombre_adultes,
.form-element-wrapper-nombre_enfants_15_18,
.form-element-wrapper-nombre_enfants_0_3,
.form-element-wrapper-nombre_enfants_4_14, .form-element-wrapper-nombre_chauffeur_bus,
.form-element-wrapper-nombre_enfants_0_6,
.form-element-wrapper-nombre_enfants_7_10,
.form-element-wrapper-nombre_enfants_11_18,
.form-element-wrapper-nombre_accompagnateurs,
.form-element-wrapper-nombre_bus,
.form-element-wrapper-nombre_daccompagnateurs,
.form-element-wrapper-nombre_personnes_fauteuil_roulant{
  @include media-breakpoint-up(lg) {
    max-width: 32%;
  }
}

.form-element-wrapper-pour_les_scolaires >div{
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.form-element-wrapper-visiter_choranche{
  min-width: 100%;
}

.fobi-form-success-text {
  text-align: center;
}

.form-element-wrapper-vous_souhaitez_reserver {
  .checkbox input {
    margin-left: -1.1em;
  }
  .checkbox {
    margin-left: 1.1em;
    line-height: 1.2em;
    margin-bottom: .5em;
  }
  .checkbox:nth-child(4) {
    margin-bottom: 2em;
  }
}
