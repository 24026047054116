#cart-form,
#rate-form,
#checkout-form {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0,0,0,0.4);
  -webkit-overflow-scrolling: touch;

  .cart-modal-content,
  .rate-modal-content,
  .checkout-modal-content {
    height: 100%;
    background-color: #fff;
  }

  .cart-jetcode-iframe,
  .rate-jetcode-iframe,
  .checkout-jetcode-iframe {
    border-radius: 0;
  }
}

@media (min-width: 768px) {
  #cart-form,
  #rate-form,
  #checkout-form {
    .cart-modal-content,
    .rate-modal-content,
    .checkout-modal-content {
      padding-top: 0;
      margin: 10px auto;
      width: 768px;
      height: initial;
      border-radius: 8px;
    }

    .cart-jetcode-iframe,
    .rate-jetcode-iframe,
    .checkout-jetcode-iframe {
      border-radius: 8px;
    }
  }
}

.accountlogin-jetcode-iframe {
  position: static !important;
}
