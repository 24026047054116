h1{
  font-family: $font_title;
  text-align: center;
  font-weight: 400;
  font-size: 60px;
  line-height: 1;
  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }
}

h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-weight: 900;
  font-family: $lato_black;
}
h2{
  font-size: 40px;
  @include media-breakpoint-down(lg) {
    font-size: 22px;
  }
}
strong, b{
  font-family: $lato_bold;
  font-weight: 700;
}
#id_content{
  h2{
    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
    font-family: $lato_black;
  }
  h3{
    position: relative;
    // &::before{
    //   content: "";
    //   width: 66px;
    //   height: 28px;
    //   display: block;
    //   background-image: linear-gradient(186deg, #A6AE00 0%, #C8D018 100%);
    //   border-radius: 10px;
    //   position: absolute;
    //   z-index: 0;
    //   left: -10px;
    //   bottom: -10px;
    //   opacity: .4;
    // }
  }
}

#id_ticketing{
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 20px;
  }
}

blockquote,
blockquote p {
  font-size: 1.2rem;
  line-height: 2rem;
}


