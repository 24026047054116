.animation {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.stepMessages {
  font-size: 1.5em;

  .stepNumber {
    font-weight: bold;
    font-size: 1.8em;
    margin-right: 1em;
  }
}
