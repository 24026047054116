#id_blog{
  .top_page{
    height: 260px;
    h1{
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}

.app-blog {
  display: grid;
  margin-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    padding-right: 15px;
    padding-left: 15px;
    grid-gap: 1rem;

    grid-template-columns: 3fr 1fr;
    grid-template-areas: "main aside";
    .post_content{
      transition: .3s all ease-in-out;
      box-shadow: 0px 15px 45px -9px rgba(0,0,0, .2);
      overflow: hidden;
      position: relative;
      background: white;
      padding: 2rem;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h2{
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 14px;
        color: theme-color('secondary');
      }
    }
    .blog-list{
      display: grid;
      gap: 2rem;
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      header, .pagination, .blog-back{
        grid-column: 1/-1;
      }
      .blog-back{
        a{
          @include button-variant(theme-color('primary'), theme-color('primary'));
          color: #fff;
          padding: 10px 15px;
          border-radius: 5px;
          text-decoration: none;

        }
      }
      .post-item{
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
          margin-bottom: 10px;
        }
        .post-visual{
          overflow: hidden;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }
  .blog_intro{
    grid-column: 1 / -1;
    h2, h3, h4, h5{
      font-size: 1.6rem;
    }
  }

  .pagination{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;

    .current{
      font-weight: bold;
      display: inline-block;
      padding: .5rem;
      margin-left: .5rem;
      margin-right: .5rem;
      border-radius: 5px;
      color:theme-color('secondary');
      position: relative;
      &::after{
        content: "]";
      }
      &::before{
        content: "[";
      }

    }
  }
}


.lead_content{
  border-left: 8px solid rgba(theme-color('secondary'), .5);
  padding: 20px;
  color: theme-color('seoncdary');
  margin-bottom: 2rem;
  font-weight: bold;
  border-radius: 4px;
}
.blog-list {
  @include media-breakpoint-up(lg) {

    padding-right: 1rem;
  }
  .post-item {
    margin-bottom: 5rem;


    .post-detail {
      list-style-type: none;
      padding-left: 0;
      display: inline-flex;

      li {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #eee;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .post-header {
      padding-top: 1rem;
      .date{
        margin-bottom: 1rem;
      }

      h3, h2 {
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}



.blog-latest-entries {
  @include media-breakpoint-up(md){

    margin-top: 3rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);

  }

  @include media-breakpoint-down(lg) {
    .post-item{
      margin-bottom: 1rem;
    }
  }

  .post-wrapper {
    background: #FFFFFF;
    box-shadow: 0 20px 40px 0 rgba(232,232,232,0.50);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    .wrapper{
      padding: 1rem;
    }
  }
  h3 {
    font-size: 20px;
    a {
      color: theme-color('primary');
      text-decoration: none;
    }
  }

  .post-item{
    &:nth-child(even){
      .post-wrapper {
        background-color: theme-color('secondary');
        color: white;
        a{
          color: white;
        }
        h3{
          color: white;
          a{
            color: inherit;
          }
        }
      }
    }
  }


  .post-visual {
    margin-bottom: .5rem;
  }

}
.see-all{
  position: relative;
  margin-top: 3rem;
  text-align: right;
  &::after{
    content: "";
    height: 1px;

    position: absolute;
    top: 50%;
    left: 0;
    background-color: #E6E6E6;
    @include media-breakpoint-down(lg) {
      width: 20%;
    }
  }
}


.blog_footer{
  padding-top: 1rem;
  border-top:1px solid theme-color('bg-light');;
}

.post-item .post_meta{
  display: flex;
  justify-content: space-between;
  ul{
    list-style: none;
    padding-left: 0;
    display: flex;
    flex: 1;
    li{
      padding-right: 0.5rem;
    }
    li + li{
      &::before{
        display: inline-block;
        padding-right: 0.5rem;
        color: #d9d9d9;
        content: "-";
      }
    }
  }
}


.sidebar{
  border-left: 1px solid #eee;
  padding-left: 20px;
  position: sticky;
  top: 100px;
  a{
    transition: all .3s ease;
    text-decoration: none;
    &:hover{
      color: theme-color('primary');
      text-decoration: none;
    }
  }
  >ul{
    margin-bottom: 2rem;
  }
  h3{
    font-size: 18px;
    color: theme-color('secondary');
  }
  .blog-latest-entries{
    display: flex;
    flex-direction: column;
    img{
      clip-path: circle(138.1% at 2% 98%);
    }
    .post-lead, .post_meta{
      display: none;
    }
    .post-wrapper{
      padding: 0.8rem;
    }
    .post-visual{
      margin-bottom: 0.5rem;
    }
    h3{
      margin-bottom: 0;
    }
  }
}


.post_tags{
  margin-bottom: 2rem;
  margin-top: 1rem;
  .blog-tag{
    align-items: center;
    background-color: #f5f5f5;
    color: #757763;
    display: inline-flex;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: .75em;
    padding-right: .75em;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 0.85rem;
    font-family: $lato_bold;
  }
}
.blog-archive, .blog-categories{
  list-style-type: none;
  padding-left: 0;
  a{
    font-size: 0.9rem;
    color: lighten(black, 50);
    display: flex;
    justify-content: space-between;
    span{
      text-transform: none;
    }
  }
}

.blog-categories{
  list-style-type: none;
  padding-left: 0;
  li{
    padding: 12px 1px;
    border-bottom: 1px solid #e3e3e3;
    font-size: 0px;
    text-transform: uppercase;
  }
}
ul.blog-tags{
  display: flex;
  font-size: 0.85rem;
  font-family: $lato_bold;
  padding-left: 0;
  list-style-type: none;
  flex-wrap: wrap;
  li{
    margin-right: 0.4rem;
    margin-bottom: 0.5rem;
    a{
      align-items: center;
      background-color: #f5f5f5;
      border-radius: 4px;
      color: #757763;
      display: inline-flex;
      height: 2em;
      justify-content: center;
      line-height: 1.5;
      padding-left: .75em;
      padding-right: .75em;
      white-space: nowrap;
      span{
        display: none;
      }
    }
  }
}


.related_posts{
  margin-bottom: 10px;
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .post-item{
    margin-bottom: 1rem;
  }
  .post-lead{
    display: none;
  }
  img{
    max-width: 100%;
    height: auto;
  }
  .post_content{
    border: 1px solid #eee;
    box-shadow: 0 20px 40px 0 rgba(232,232,232,0.50);
    border-top: 0;
    padding: 1rem;

  }
  h2{
    font-size: 20px;
    margin-top: 1rem;
    a{
      color: inherit;
    }
  }
}
#id_blog{
  .blog-content{
    h2{
      font-size: 26px;
      color: theme-color('primary');
      position: relative;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      &::after{
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        height: 5px;
        width: 40px;
        background-color:theme-color('secondary');
        background-image: linear-gradient(160deg, theme-color('secondary') 0%, #80D0C7 100%);

      }
    }
    h3{
      font-size: 23px;
    }
    h4{
      font-size: 20px;
    }
    h5{
      font-size: 18px;
    }
    h{
      font-size: 17px;
    }
    h3,h4,h5,h6{
      font-family: $lato_bold;
    }
  }
}


.blog_header{
  padding-top: 1rem;
  border-top: 1px solid #eee;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  background-color: #F5F5F5;

  h1{
    font-family: $lato_bold;
    text-align: left;
    font-size: 28px;
    line-height: 1.2;
  }
}


.social-share-buttons{
  display: flex;
  a{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border: 1px solid #eee;
    margin-right: 10px;
    border-radius: 40px;
    color: inherit;
    background-color: white;
    &:hover{
      color: theme-color('primary');
      text-decoration: none;
    }
  }
}
