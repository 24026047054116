@charset "UTF-8";
// Core variables and mixins
@import "base/custom";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free-webfonts/scss/fa-brands";
@import "node_modules/@fortawesome/fontawesome-free-webfonts/scss/fa-regular";
@import "node_modules/@fortawesome/fontawesome-free-webfonts/scss/fa-solid";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";


@import "modules/sprite";
// base
@import "base/typos";
@import "base/common";

//layout
@import "layout/header";
@import "layout/main";
@import "layout/footer";
@import "layout/choranche";
@import "layout/jardin";
@import "layout/bateau";
@import "layout/thais";

// modules
@import "modules/columns";
@import "modules/cookieoptin";
@import "modules/blog";
@import "modules/modules";
@import "modules/teaser";
@import "modules/form";
@import "modules/animations";
@import "modules/jetcodes";
@import "modules/faq";
@import "modules/tables";
