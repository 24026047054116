.brevo_form_page{
  margin-bottom: 4rem;

  .brevo_title{
    margin-bottom: 2rem;
  }
  form{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    label {
      color: #212529;

      @include media-breakpoint-up(lg){
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm){
        margin-bottom: 1rem;
      }
    }

    .form_email{
      margin-bottom: 1rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      label{
        margin-right: 1rem;
      }
    }
    .form_rgpd_optin{
      font-size: 14px;

      @include media-breakpoint-down(md){
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        input{
          margin-top: .2rem;
          margin-right: .5rem;
        }
      }
    }

    #id_email{
      margin-right: 1rem;
      background: #FBFAFF;
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      border: 1px solid #ced4da;
      height: 35px;
      padding: 0.375rem 0.75rem;
      width: 300px;

      @include media-breakpoint-down(sm){
        margin-bottom: 1rem;
      }

      &:focus{
        color: #495057;
        background-color: #ffffff;
        border-color: #de8b8a;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(182, 52, 51, 0.25);
      }
    }
  }
}

#id_index{
  .title_page{
    @include media-breakpoint-down(md) {
      padding: 10px;
      p{
        font-size: 16px;
      }
    }
  }
}

.activities{
  @include media-breakpoint-down(lg) {
    overflow: hidden;
    .slick-prev{
      left: 10px;
      z-index: 99;
    }
    .slick-next{
      right: 10px;
      z-index: 99;
    }
  }
  ul{
    list-style: none;
    padding-left: 0;
    display: grid;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);

    }
    @include media-breakpoint-down(md) {

    }
    li{
      img{
        min-width: 100%;
      }
      figure{
        position: relative;
        .img-wrapper{
          position: relative;
          &::after{
            content: "";
            position: absolute;
            height: 50%;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #C8D018 100%);
          }
        }
        figcaption{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          pointer-events: none;
          display: flex;
          align-items: flex-end;
          >div{
            width: 100%;
            padding: 1rem;
            @include media-breakpoint-up(lg) {
              border-bottom: 1px solid #fff;
              min-height: 130px;
              display: flex;
              align-items: center;
            }
            .description{
              display: none;
            }
          }
          .title{
            font-weight: bold;
            color:white;
            font-size: 22px;
            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }
          }
        }
      }
      &:nth-child(2){
        figure .img-wrapper:after{
          background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #77B5B1 100%);
        }
      }
      &:nth-child(3){
        figure .img-wrapper:after{
          background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #6C6F8F 100%);
        }
      }
      &:nth-child(4){
        figure .img-wrapper:after{
          background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #B63433 100%);
        }
      }
    }
  }
}
.home_carousel{
  position: relative;
  .carousel_wrapper{
    background-position: center;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      height: 80vh;
      max-height: 600px;
    }
    @include media-breakpoint-down(lg) {
      height: 300px;
      opacity: .6;
    }
  }
}
.slideshow{
  position: relative;
  .bg{
    height: 80vh;
    max-height: 600px;
    background-size: cover;
  }
  .slick-slide{
    background-color: theme-color('dark');
    margin-bottom: -10px;
    @include media-breakpoint-down(lg) {
    }
  }
  h1{
    color: white;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include media-breakpoint-up(lg) {
      font-size: 100px;

    }
    @include media-breakpoint-down(lg) {
      font-size: 50px;
      line-height: 1;

    }
  }
}
.intro{
  .menu{
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr ;
      gap: 1rem;

      grid-template-areas:
      "left right . ."
      "bottom bottom . ."
      ". . . .";
      max-width: 100%;
      .teaser-text-overlay {
        &:nth-child(1){
          grid-area: left;
          display: flex;
          align-items: flex-end;

        }
        &:nth-child(2){
          grid-area: right;
        }
        &:nth-child(3){
          grid-area: bottom;
          >div{
            margin-left: 2rem;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .teaser-text-overlay{
        margin-bottom: 20px;
      }
    }
  }
}
.section{
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include media-breakpoint-down(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.map_wrapper{
  background-color: theme-color('bg-light');
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .sites{
    display: grid;
    margin: 0  auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    li{
      position: relative;
      text-align: center;
      position: relative;
      padding: 10px;
      .img-container{
        transform: translateY(30px);
        width: 200px;
        height: 200px;
        margin: 0 auto;
        background-color: white;
        background-image: url('../img/circle-red.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        @include media-breakpoint-only(md) {
          width: 140px;
          height: 140px;
        }
        .logo{
          img{
            max-width: 120px;
          }
        }
      }
      &.bateau{
        .img-container{
          background-image: url('../img/circle-bleu.png');
        }
      }
      &.jardin{
        .img-container{
          background-image: url('../img/circle-cyan.png');
        }
      }
      &.choranche{
        .img-container{
          background-image: url('../img/circle-green.png');
        }
      }
      &:hover{
        svg >path{
          animation: dash 1.5s linear forwards;
        }
      }
    }
  }
  .map{
    background-image: url(../img/map.png);
    background-position: center -160px;
    height: 790px;
    position: relative;
    @include media-breakpoint-only(md) {
      display: none;
    }
    .pin{
      position: absolute;
      display: block;
      width: 30px;
      z-index: 1;
      transition-duration: .3s;
    }
  }
  .sites-pin-logo-wrapper svg{
    position: absolute;
    >path{
      transition-duration: 1s;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
    }
  }

}
.thais{
  .pin{
    bottom: -358px;
    left: 414px;
  }
  svg{
    left: 120px;
  }
}
.bateau{
  svg{
    left: 13px;
  }
  .pin{
    bottom: -348px;
    left: 142px;
  }
  .pin2{
    bottom: -171px;
    left: 219px;
  }
}
.jardin{
  svg{
    left: -47px;
  }
  .pin{
    bottom: -172px;
    left: -56px;
  }

}
.choranche{
  svg{
    left: -106px;
  }
  .pin{
    bottom: -359px;
    left: -46px;
  }
}

.bottom_activities{
  .teaser-text-after {
    background: #FFFFFF;
    box-shadow: 0 20px 40px 0 rgba(232,232,232,0.50);
    padding: 10px;
    text-align: center;
    border-top: 3px solid #eee;

    a {
      color: inherit;
    }

    .menu-content{
      padding-top: 1rem;
      border-top: 1px solid #eee;
      margin-top: 1rem;
      padding: 1rem;
      font-family: $lato_bold;
    }
  }
}
.title_page{
  margin-bottom: 2rem;
  margin-top: 2rem;
  p{
    color: theme-color('secondary');
    font-size: 20px;
  }
}

.blog{
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-image: linear-gradient(90deg, #FFFFFF 0%, #FFFFFE 50%, #F8F8F7 50%, #F7F7F6 100%);
}
.testimonials{
  background-image: linear-gradient(to left, #FFFFFF 0%, #FFFFFE 50%, #F8F8F7 50%, #F7F7F6 100%);
  .testimonials_slider{
    .wrapper{
      padding: 2rem;
      margin: 1rem;
      border-radius: 1rem;
      background-color: theme-color('dark');
      color: white;
      .author{
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
          margin-left: 10px;
          font-weight: bold;
        }
        img{
          border: 3px solid theme-color('primary');
          border-radius: 50%;
          width: 50px;
          height: auto;
        }
      }
    }
    .slick-current{
      .wrapper{
        background-color: theme-color('secondary');
      }
    }
  }

}

.events{
  background-color:#F8F8F8 ;
  .events_wrapper{
    position: relative;
    >div{
      @include media-breakpoint-up(sm) {

        display: flex;
        align-items: center;
      }
    }
  }
  .carousel__content{
    padding-right: 2rem;
    @include media-breakpoint-up(sm) {
      flex: 1 0 50%;
    }
    @include media-breakpoint-down(sm) {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(black, .5);
      color: white;
    }
  }
  .carousel__img{
    position: relative;
    @include media-breakpoint-up(sm) {
      img{
        max-width: 100%;
      }
    }
    &::after, &::before{
      @include media-breakpoint-up(sm) {

        content: "";
        position: absolute;
        height: 90%;
        width: 20px;
        background-image: linear-gradient(180deg, #C8D018 0%, #B0B715 100%);
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &::before{
      left: -20px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &::after{
      right: -20px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    img{
      border-radius: 10px;
    }
  }
}
.events_carousel{
  .events_wrapper{
    padding-right: 3rem;
  }
  .carousel__content{
    padding: 2rem;
  }
}

.other_activities{
  .menu_others{
    margin-top: 3rem;

    .teaser-text-after {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
      }

      a {
        color: $body-color;
        &:hover {
          text-decoration: none;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .menu-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .title {
      padding: 1rem;
      text-align: center;
      font-family: $lato_black;
      font-size: 22px;
    }
  }
}


.teaser-text-after{
  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
  .shadow_effect{
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    display: block;
    color: inherit !important;
    img {
      max-width: 100%;
      height: auto;
    }

    .menu-content {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .title {
      padding: 1rem;
      text-align: center;
      font-family: $lato_black;
      font-size: 22px;
    }
  }
}

/* ==========================================================================
   cms content
========================================================================== */

#id_content{
  .menu--widgets{
    display: grid;
  }
}
.top_page{
  @include media-breakpoint-up(lg) {
    height: 400px;

  }
  background-color: #333;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-blend-mode: overlay;
  @include media-breakpoint-down(sm) {
    height: 300px;
  }
  h1{
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 30%;
    color: white;
    font-size: 70px;
    @include media-breakpoint-down(sm) {
      font-size: 50px;
    }
  }
}
.search_page{
  .top_page{
    height: 200px;
  }
}

#id_contact{
  .top_page{
    @include media-breakpoint-up(lg) {
      height: 300px;
    }
  }
}

.menu--widgets.sub{
  list-style: none;
  padding-left: 0;
  display: grid;
  @include media-breakpoint-up(lg) {

    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .menu--item {
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
    figure{
      background: #FFFFFF;
      box-shadow: 0 20px 40px 0 rgba(232,232,232,0.50);
      border-radius: 8px;
      overflow: hidden;
      text-align: center;
      .menu_item-caption{
        padding: 1rem;
      }
      .title{
        font-size: 18px;
        font-family: $lato_black;
        transition: all .3s ease;
        transform: translateY(10px);
      }
      .description{
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease;
        transform: translateY(100px);

      }
      &:hover{
        .title{
          transform: translateY(0);
        }
        .description{
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}


.djangocms_faq_container{
  h3{
    margin-bottom: 2rem;
  }
  .djangocms_faq_keywords{
    display: none;
  }
  .djangocms_faq_child{
    background-color: #FAFAFA;
    padding: 1rem;
    padding-top: 2rem;
    color: theme-color('secondary');
  }
}
details{
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  .hide{
    display: none;
  }
  &[open]{
    .open{
      display: none;
    }
    .hide{
      display: block;
    }
  }
  summary{
    display: flex;
    justify-content: space-between;
  }
  h5{
    font-size: 18px;
    font-family: $lato_bold;
  }
  summary::marker{
    color: #eee;
  }
}
/* ==========================================================================
   search page
   ========================================================================== */

.plugin__search{
  @include make-container();
  max-width: 1114px;
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 2fr;
  }
  .search-results{
    ul{
      list-style-type: none;
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        margin-top: 30px;
      }
      li{
        padding: 0.5rem;
        transition: background-color 0.2s;
      }
      li:not(:last-child){
        border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
      }
      li:hover{
        background-color: #f2f2f2;
        transition: background-color 0.2s;
      }
    }
    .highlighted{
      color: #000;
      font-weight: bold;
      text-decoration-color: rgba(21, 132, 196, 0.2);
      text-decoration-style: normal;
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;
      box-shadow: inset 0 -5px 0 0 rgba(theme-color('primary'), 0.2);
    }
  }
}

.frm__search{
  .wrapper{
    background-color: darken(white, 5);
    padding: 1rem;
    p{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    label, input{
      display: block;
      width: 100%;
    }
    input{
      padding: 10px;
      border: 1px solid darken(white, 15);
    }
  }
}

.djangocms_popup{
  border: 1px solid #eee;
  box-shadow: 1px 1px 11px 1px rgba(black , .2);
  padding: 2rem;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-image: linear-gradient(224deg, transparent 0%, transparent 30%,rgba(249, 249, 249,0.02) 30%, rgba(249, 249, 249,0.02) 36%,transparent 36%, transparent 100%),linear-gradient(345deg, transparent 0%, transparent 53%,rgba(140, 140, 140,0.02) 53%, rgba(140, 140, 140,0.02) 63%,transparent 63%, transparent 100%),linear-gradient(284deg, transparent 0%, transparent 28%,rgba(1, 1, 1,0.02) 28%, rgba(1, 1, 1,0.02) 38%,transparent 38%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
  .djangocms_popup_close{
    text-align: right;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items:center;
    background-color: #222;
    color: white;
    border-radius: 30px;
  }
  p:last-child{
    margin-bottom: 0;
  }
}


#id_ticketing{
  .top_page{
    height: 300px;
  }
}

.ticket{
  margin-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;

  border-radius: 10px;
  img{
    border-radius: 10px;
  }
  .image_wrapper, .content_wrapper{
    padding: 10px;
    position: relative;
  }
  .image_wrapper{
    &::before, &::after{
      content:"";
      display: block;
      width: 30px;
      height: 15px;
      background-color: #fbfbfb;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      border: 1px solid #eee;
      border-bottom: 0;
      position: absolute;
    }
  }
  .content_wrapper{
    &::before{
      content:"";
      position: absolute;
      border: 1px dashed #eee;
    }
    .price{
      margin-top: 10px;
      font-family: $lato_black;
      font-size: 35px;
      .small{
        margin-bottom: 0;
        font-family: $lato_light;
        font-size: 16px;
        line-height: 16px;
      }
    }
    .title{
      font-size: 22px;
      font-family: $lato_bold;
      text-align: center;
      p{
        margin-bottom: 0;
        &.small{
          font-size: 20px;
          font-family: $lato_light;
        }
      }
    }
  }
  &:not(.horizontal){
    box-shadow: 0 20px 40px 0 rgba(232,232,232,0.50);
    .content_wrapper{
      text-align: center;
      padding-top: 40px;
      &::before{
        left: 50%;
        top: 7px;
        transform: translateX(-50%);
        width: 80%;
        height: 1px;
      }
    }
    .image_wrapper{
      &::before, &::after{
        bottom: -15px;
      }
      &::before{
        left: -10px;
        transform: rotate(
          90deg
        );
      }
      &::after{
        right: -10px;
        transform: rotate(
          -90deg
        );
      }
    }
  }
  &.horizontal{
    box-shadow: 0 2px 40px 0 rgba(232,232,232,0.50);
    display: grid;
    @include media-breakpoint-up(md) {

      grid-template-columns: 260px 1fr;
      grid-auto-rows: 1fr;
    }
    .content_wrapper{
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      &::before{
        @include media-breakpoint-up(md) {
          left: 4px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 80%;
        }
        @include media-breakpoint-down(sm) {
          width: 90%;
          top: 0;
        }
      }
    }
    .image_wrapper{
      &::before, &::after{
        content:"";
        display: block;
        width: 30px;
        height: 15px;
        background-color: #f6f6f6;
        border-top-left-radius: 110px;
        border-top-right-radius: 110px;
        border: 1px solid #eee;
        border-bottom: 0;
        position: absolute;
        right: -20px;

      }
      &::before{
        @include media-breakpoint-up(md) {
          top: -1px;
          transform: rotate(
            180deg
          );
        }
        @include media-breakpoint-down(sm) {
          bottom: -10px;
          left: -10px;
          right: unset;
          transform: rotate(
            90deg
          );
        }
      }
      &::after{
        @include media-breakpoint-up(md) {

          bottom: -1px;
          transform: rotate(
            0deg
          );
        }
        @include media-breakpoint-down(sm) {
          bottom: -10px;
          right: -10px;
          transform: rotate(
            -90deg
          );
        }
      }
    }
    &.disabled{
      pointer-events: none;
      opacity: .4;
      filter: grayscale(1);
      .btn{
        background-color: #666 !important;
        border: 0 !important;
      }
      img{
        filter: grayscale(1);
      }
    }
  }
}


.choranche{
  .ticket.horizontal{
    .ticket__body{
      .ticket__section{

        .price{
          background-color: theme-color('choranche');
          h3{
            color: white;
          }
        }
      }
    }
  }
}


summary::-webkit-details-marker {
  display: none
}

.faq_seargh{
  display: flex;
  justify-content: center;
  margin-top: -30px;
  .wrapper{
    display: flex;
    input[type="text"]{
      background: #FBFAFF;
      box-shadow: 0 2px 11px 0 rgba(0,0,0,0.15);
      border-radius: 10px;
      border: 0;
      padding: .5rem 1rem;
      @include media-breakpoint-up(lg) {

        min-width: 400px;
      }
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button{
      margin-left: -10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      min-width: 70px;
      box-shadow: 0 2px 11px 0 rgba(0,0,0,0.15);
    }
    input, button{
      height: 50px;

    }
  }
}


.choranche{
  .djangocms_faq_results{
    a{
      &:hover{
        color: theme-color('choranche') !important;
      }
    }
  }
}
.jardin{
  .djangocms_faq_results{
    a{
      &:hover{
        color: theme-color('jardin') !important;
      }
    }
  }
}
.bateau{
  .djangocms_faq_results{
    a{
      &:hover{
        color: theme-color('bateau') !important;
      }
    }
  }
}
.thais{
  .djangocms_faq_results{
    a{
      &:hover{
        color: theme-color('thais') !important;
      }
    }
  }
}


.section_bg{
  background-color: #222;
  background-blend-mode: overlay;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
