
body.jardin{
  .navbar-toggler{
    background-color: theme-color('jardin');
  }
  .btn{
    &.btn-primary{
      background-color: theme-color('jardin');
      border-color: theme-color('jardin');
      &:hover{
        background-color: darken(theme-color('jardin'),5);
      }
    }
  }
  .content{
    a:not(.btn){
      color: theme-color('jardin');
    }
  }

  .breadcrumb-item {
    a{
      color: theme-color('jardin');
    }
  }
  .icn{
    color: theme-color('jardin');
  }

  header.header{
    .navbar-nav.nav_sub{
      .nav-item{
        &.active,  &.ancestor{
          a.nav-link{
            color: theme-color('jardin') !important;
          }
        }
      }

    }
  }

  .events .carousel__img::after,
  .events .carousel__img::before{
    background-image: linear-gradient(
      180deg
      , #A54D25 0%, #e67f4f 100%);
  }
}
