// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-contact-name: 'sprite_contact';
$sprite-contact-x: 80px;
$sprite-contact-y: 29px;
$sprite-contact-offset-x: -80px;
$sprite-contact-offset-y: -29px;
$sprite-contact-width: 23px;
$sprite-contact-height: 15px;
$sprite-contact-total-width: 154px;
$sprite-contact-total-height: 151px;
$sprite-contact-image: '../img/sprite.png';
$sprite-contact: (80px, 29px, -80px, -29px, 23px, 15px, 154px, 151px, '../img/sprite.png', 'sprite_contact', );
$sprite-explore-name: 'sprite_explore';
$sprite-explore-x: 0px;
$sprite-explore-y: 91px;
$sprite-explore-offset-x: 0px;
$sprite-explore-offset-y: -91px;
$sprite-explore-width: 31px;
$sprite-explore-height: 30px;
$sprite-explore-total-width: 154px;
$sprite-explore-total-height: 151px;
$sprite-explore-image: '../img/sprite.png';
$sprite-explore: (0px, 91px, 0px, -91px, 31px, 30px, 154px, 151px, '../img/sprite.png', 'sprite_explore', );
$sprite-explore-hover-bateau-name: 'sprite_explore_hover_bateau';
$sprite-explore-hover-bateau-x: 31px;
$sprite-explore-hover-bateau-y: 91px;
$sprite-explore-hover-bateau-offset-x: -31px;
$sprite-explore-hover-bateau-offset-y: -91px;
$sprite-explore-hover-bateau-width: 31px;
$sprite-explore-hover-bateau-height: 30px;
$sprite-explore-hover-bateau-total-width: 154px;
$sprite-explore-hover-bateau-total-height: 151px;
$sprite-explore-hover-bateau-image: '../img/sprite.png';
$sprite-explore-hover-bateau: (31px, 91px, -31px, -91px, 31px, 30px, 154px, 151px, '../img/sprite.png', 'sprite_explore_hover_bateau', );
$sprite-explore-hover-choranche-name: 'sprite_explore_hover_choranche';
$sprite-explore-hover-choranche-x: 62px;
$sprite-explore-hover-choranche-y: 91px;
$sprite-explore-hover-choranche-offset-x: -62px;
$sprite-explore-hover-choranche-offset-y: -91px;
$sprite-explore-hover-choranche-width: 31px;
$sprite-explore-hover-choranche-height: 30px;
$sprite-explore-hover-choranche-total-width: 154px;
$sprite-explore-hover-choranche-total-height: 151px;
$sprite-explore-hover-choranche-image: '../img/sprite.png';
$sprite-explore-hover-choranche: (62px, 91px, -62px, -91px, 31px, 30px, 154px, 151px, '../img/sprite.png', 'sprite_explore_hover_choranche', );
$sprite-explore-hover-jardin-name: 'sprite_explore_hover_jardin';
$sprite-explore-hover-jardin-x: 93px;
$sprite-explore-hover-jardin-y: 91px;
$sprite-explore-hover-jardin-offset-x: -93px;
$sprite-explore-hover-jardin-offset-y: -91px;
$sprite-explore-hover-jardin-width: 31px;
$sprite-explore-hover-jardin-height: 30px;
$sprite-explore-hover-jardin-total-width: 154px;
$sprite-explore-hover-jardin-total-height: 151px;
$sprite-explore-hover-jardin-image: '../img/sprite.png';
$sprite-explore-hover-jardin: (93px, 91px, -93px, -91px, 31px, 30px, 154px, 151px, '../img/sprite.png', 'sprite_explore_hover_jardin', );
$sprite-explore-hover-thais-name: 'sprite_explore_hover_thais';
$sprite-explore-hover-thais-x: 0px;
$sprite-explore-hover-thais-y: 121px;
$sprite-explore-hover-thais-offset-x: 0px;
$sprite-explore-hover-thais-offset-y: -121px;
$sprite-explore-hover-thais-width: 31px;
$sprite-explore-hover-thais-height: 30px;
$sprite-explore-hover-thais-total-width: 154px;
$sprite-explore-hover-thais-total-height: 151px;
$sprite-explore-hover-thais-image: '../img/sprite.png';
$sprite-explore-hover-thais: (0px, 121px, 0px, -121px, 31px, 30px, 154px, 151px, '../img/sprite.png', 'sprite_explore_hover_thais', );
$sprite-groupes-name: 'sprite_groupes';
$sprite-groupes-x: 120px;
$sprite-groupes-y: 66px;
$sprite-groupes-offset-x: -120px;
$sprite-groupes-offset-y: -66px;
$sprite-groupes-width: 25px;
$sprite-groupes-height: 19px;
$sprite-groupes-total-width: 154px;
$sprite-groupes-total-height: 151px;
$sprite-groupes-image: '../img/sprite.png';
$sprite-groupes: (120px, 66px, -120px, -66px, 25px, 19px, 154px, 151px, '../img/sprite.png', 'sprite_groupes', );
$sprite-map-name: 'sprite_map';
$sprite-map-x: 0px;
$sprite-map-y: 58px;
$sprite-map-offset-x: 0px;
$sprite-map-offset-y: -58px;
$sprite-map-width: 34px;
$sprite-map-height: 33px;
$sprite-map-total-width: 154px;
$sprite-map-total-height: 151px;
$sprite-map-image: '../img/sprite.png';
$sprite-map: (0px, 58px, 0px, -58px, 34px, 33px, 154px, 151px, '../img/sprite.png', 'sprite_map', );
$sprite-map-hover-bateau-name: 'sprite_map_hover_bateau';
$sprite-map-hover-bateau-x: 34px;
$sprite-map-hover-bateau-y: 58px;
$sprite-map-hover-bateau-offset-x: -34px;
$sprite-map-hover-bateau-offset-y: -58px;
$sprite-map-hover-bateau-width: 34px;
$sprite-map-hover-bateau-height: 33px;
$sprite-map-hover-bateau-total-width: 154px;
$sprite-map-hover-bateau-total-height: 151px;
$sprite-map-hover-bateau-image: '../img/sprite.png';
$sprite-map-hover-bateau: (34px, 58px, -34px, -58px, 34px, 33px, 154px, 151px, '../img/sprite.png', 'sprite_map_hover_bateau', );
$sprite-map-hover-choranche-name: 'sprite_map_hover_choranche';
$sprite-map-hover-choranche-x: 68px;
$sprite-map-hover-choranche-y: 58px;
$sprite-map-hover-choranche-offset-x: -68px;
$sprite-map-hover-choranche-offset-y: -58px;
$sprite-map-hover-choranche-width: 34px;
$sprite-map-hover-choranche-height: 33px;
$sprite-map-hover-choranche-total-width: 154px;
$sprite-map-hover-choranche-total-height: 151px;
$sprite-map-hover-choranche-image: '../img/sprite.png';
$sprite-map-hover-choranche: (68px, 58px, -68px, -58px, 34px, 33px, 154px, 151px, '../img/sprite.png', 'sprite_map_hover_choranche', );
$sprite-map-hover-jardin-name: 'sprite_map_hover_jardin';
$sprite-map-hover-jardin-x: 120px;
$sprite-map-hover-jardin-y: 0px;
$sprite-map-hover-jardin-offset-x: -120px;
$sprite-map-hover-jardin-offset-y: 0px;
$sprite-map-hover-jardin-width: 34px;
$sprite-map-hover-jardin-height: 33px;
$sprite-map-hover-jardin-total-width: 154px;
$sprite-map-hover-jardin-total-height: 151px;
$sprite-map-hover-jardin-image: '../img/sprite.png';
$sprite-map-hover-jardin: (120px, 0px, -120px, 0px, 34px, 33px, 154px, 151px, '../img/sprite.png', 'sprite_map_hover_jardin', );
$sprite-map-hover-thais-name: 'sprite_map_hover_thais';
$sprite-map-hover-thais-x: 120px;
$sprite-map-hover-thais-y: 33px;
$sprite-map-hover-thais-offset-x: -120px;
$sprite-map-hover-thais-offset-y: -33px;
$sprite-map-hover-thais-width: 34px;
$sprite-map-hover-thais-height: 33px;
$sprite-map-hover-thais-total-width: 154px;
$sprite-map-hover-thais-total-height: 151px;
$sprite-map-hover-thais-image: '../img/sprite.png';
$sprite-map-hover-thais: (120px, 33px, -120px, -33px, 34px, 33px, 154px, 151px, '../img/sprite.png', 'sprite_map_hover_thais', );
$sprite-search-name: 'sprite_search';
$sprite-search-x: 102px;
$sprite-search-y: 58px;
$sprite-search-offset-x: -102px;
$sprite-search-offset-y: -58px;
$sprite-search-width: 15px;
$sprite-search-height: 16px;
$sprite-search-total-width: 154px;
$sprite-search-total-height: 151px;
$sprite-search-image: '../img/sprite.png';
$sprite-search: (102px, 58px, -102px, -58px, 15px, 16px, 154px, 151px, '../img/sprite.png', 'sprite_search', );
$sprite-tickets-name: 'sprite_tickets';
$sprite-tickets-x: 0px;
$sprite-tickets-y: 0px;
$sprite-tickets-offset-x: 0px;
$sprite-tickets-offset-y: 0px;
$sprite-tickets-width: 40px;
$sprite-tickets-height: 29px;
$sprite-tickets-total-width: 154px;
$sprite-tickets-total-height: 151px;
$sprite-tickets-image: '../img/sprite.png';
$sprite-tickets: (0px, 0px, 0px, 0px, 40px, 29px, 154px, 151px, '../img/sprite.png', 'sprite_tickets', );
$sprite-tickets-hover-bateau-name: 'sprite_tickets_hover_bateau';
$sprite-tickets-hover-bateau-x: 40px;
$sprite-tickets-hover-bateau-y: 0px;
$sprite-tickets-hover-bateau-offset-x: -40px;
$sprite-tickets-hover-bateau-offset-y: 0px;
$sprite-tickets-hover-bateau-width: 40px;
$sprite-tickets-hover-bateau-height: 29px;
$sprite-tickets-hover-bateau-total-width: 154px;
$sprite-tickets-hover-bateau-total-height: 151px;
$sprite-tickets-hover-bateau-image: '../img/sprite.png';
$sprite-tickets-hover-bateau: (40px, 0px, -40px, 0px, 40px, 29px, 154px, 151px, '../img/sprite.png', 'sprite_tickets_hover_bateau', );
$sprite-tickets-hover-choranche-name: 'sprite_tickets_hover_choranche';
$sprite-tickets-hover-choranche-x: 0px;
$sprite-tickets-hover-choranche-y: 29px;
$sprite-tickets-hover-choranche-offset-x: 0px;
$sprite-tickets-hover-choranche-offset-y: -29px;
$sprite-tickets-hover-choranche-width: 40px;
$sprite-tickets-hover-choranche-height: 29px;
$sprite-tickets-hover-choranche-total-width: 154px;
$sprite-tickets-hover-choranche-total-height: 151px;
$sprite-tickets-hover-choranche-image: '../img/sprite.png';
$sprite-tickets-hover-choranche: (0px, 29px, 0px, -29px, 40px, 29px, 154px, 151px, '../img/sprite.png', 'sprite_tickets_hover_choranche', );
$sprite-tickets-hover-jardin-name: 'sprite_tickets_hover_jardin';
$sprite-tickets-hover-jardin-x: 40px;
$sprite-tickets-hover-jardin-y: 29px;
$sprite-tickets-hover-jardin-offset-x: -40px;
$sprite-tickets-hover-jardin-offset-y: -29px;
$sprite-tickets-hover-jardin-width: 40px;
$sprite-tickets-hover-jardin-height: 29px;
$sprite-tickets-hover-jardin-total-width: 154px;
$sprite-tickets-hover-jardin-total-height: 151px;
$sprite-tickets-hover-jardin-image: '../img/sprite.png';
$sprite-tickets-hover-jardin: (40px, 29px, -40px, -29px, 40px, 29px, 154px, 151px, '../img/sprite.png', 'sprite_tickets_hover_jardin', );
$sprite-tickets-hover-thais-name: 'sprite_tickets_hover_thais';
$sprite-tickets-hover-thais-x: 80px;
$sprite-tickets-hover-thais-y: 0px;
$sprite-tickets-hover-thais-offset-x: -80px;
$sprite-tickets-hover-thais-offset-y: 0px;
$sprite-tickets-hover-thais-width: 40px;
$sprite-tickets-hover-thais-height: 29px;
$sprite-tickets-hover-thais-total-width: 154px;
$sprite-tickets-hover-thais-total-height: 151px;
$sprite-tickets-hover-thais-image: '../img/sprite.png';
$sprite-tickets-hover-thais: (80px, 0px, -80px, 0px, 40px, 29px, 154px, 151px, '../img/sprite.png', 'sprite_tickets_hover_thais', );
$sprite-contact-2x-name: 'sprite_contact@2x';
$sprite-contact-2x-x: 160px;
$sprite-contact-2x-y: 58px;
$sprite-contact-2x-offset-x: -160px;
$sprite-contact-2x-offset-y: -58px;
$sprite-contact-2x-width: 46px;
$sprite-contact-2x-height: 30px;
$sprite-contact-2x-total-width: 308px;
$sprite-contact-2x-total-height: 302px;
$sprite-contact-2x-image: '../img/sprite-2x.png';
$sprite-contact-2x: (160px, 58px, -160px, -58px, 46px, 30px, 308px, 302px, '../img/sprite-2x.png', 'sprite_contact@2x', );
$sprite-explore-2x-name: 'sprite_explore@2x';
$sprite-explore-2x-x: 0px;
$sprite-explore-2x-y: 182px;
$sprite-explore-2x-offset-x: 0px;
$sprite-explore-2x-offset-y: -182px;
$sprite-explore-2x-width: 62px;
$sprite-explore-2x-height: 60px;
$sprite-explore-2x-total-width: 308px;
$sprite-explore-2x-total-height: 302px;
$sprite-explore-2x-image: '../img/sprite-2x.png';
$sprite-explore-2x: (0px, 182px, 0px, -182px, 62px, 60px, 308px, 302px, '../img/sprite-2x.png', 'sprite_explore@2x', );
$sprite-explore-hover-bateau-2x-name: 'sprite_explore_hover_bateau@2x';
$sprite-explore-hover-bateau-2x-x: 62px;
$sprite-explore-hover-bateau-2x-y: 182px;
$sprite-explore-hover-bateau-2x-offset-x: -62px;
$sprite-explore-hover-bateau-2x-offset-y: -182px;
$sprite-explore-hover-bateau-2x-width: 62px;
$sprite-explore-hover-bateau-2x-height: 60px;
$sprite-explore-hover-bateau-2x-total-width: 308px;
$sprite-explore-hover-bateau-2x-total-height: 302px;
$sprite-explore-hover-bateau-2x-image: '../img/sprite-2x.png';
$sprite-explore-hover-bateau-2x: (62px, 182px, -62px, -182px, 62px, 60px, 308px, 302px, '../img/sprite-2x.png', 'sprite_explore_hover_bateau@2x', );
$sprite-explore-hover-choranche-2x-name: 'sprite_explore_hover_choranche@2x';
$sprite-explore-hover-choranche-2x-x: 124px;
$sprite-explore-hover-choranche-2x-y: 182px;
$sprite-explore-hover-choranche-2x-offset-x: -124px;
$sprite-explore-hover-choranche-2x-offset-y: -182px;
$sprite-explore-hover-choranche-2x-width: 62px;
$sprite-explore-hover-choranche-2x-height: 60px;
$sprite-explore-hover-choranche-2x-total-width: 308px;
$sprite-explore-hover-choranche-2x-total-height: 302px;
$sprite-explore-hover-choranche-2x-image: '../img/sprite-2x.png';
$sprite-explore-hover-choranche-2x: (124px, 182px, -124px, -182px, 62px, 60px, 308px, 302px, '../img/sprite-2x.png', 'sprite_explore_hover_choranche@2x', );
$sprite-explore-hover-jardin-2x-name: 'sprite_explore_hover_jardin@2x';
$sprite-explore-hover-jardin-2x-x: 186px;
$sprite-explore-hover-jardin-2x-y: 182px;
$sprite-explore-hover-jardin-2x-offset-x: -186px;
$sprite-explore-hover-jardin-2x-offset-y: -182px;
$sprite-explore-hover-jardin-2x-width: 62px;
$sprite-explore-hover-jardin-2x-height: 60px;
$sprite-explore-hover-jardin-2x-total-width: 308px;
$sprite-explore-hover-jardin-2x-total-height: 302px;
$sprite-explore-hover-jardin-2x-image: '../img/sprite-2x.png';
$sprite-explore-hover-jardin-2x: (186px, 182px, -186px, -182px, 62px, 60px, 308px, 302px, '../img/sprite-2x.png', 'sprite_explore_hover_jardin@2x', );
$sprite-explore-hover-thais-2x-name: 'sprite_explore_hover_thais@2x';
$sprite-explore-hover-thais-2x-x: 0px;
$sprite-explore-hover-thais-2x-y: 242px;
$sprite-explore-hover-thais-2x-offset-x: 0px;
$sprite-explore-hover-thais-2x-offset-y: -242px;
$sprite-explore-hover-thais-2x-width: 62px;
$sprite-explore-hover-thais-2x-height: 60px;
$sprite-explore-hover-thais-2x-total-width: 308px;
$sprite-explore-hover-thais-2x-total-height: 302px;
$sprite-explore-hover-thais-2x-image: '../img/sprite-2x.png';
$sprite-explore-hover-thais-2x: (0px, 242px, 0px, -242px, 62px, 60px, 308px, 302px, '../img/sprite-2x.png', 'sprite_explore_hover_thais@2x', );
$sprite-groupes-2x-name: 'sprite_groupes@2x';
$sprite-groupes-2x-x: 240px;
$sprite-groupes-2x-y: 132px;
$sprite-groupes-2x-offset-x: -240px;
$sprite-groupes-2x-offset-y: -132px;
$sprite-groupes-2x-width: 50px;
$sprite-groupes-2x-height: 38px;
$sprite-groupes-2x-total-width: 308px;
$sprite-groupes-2x-total-height: 302px;
$sprite-groupes-2x-image: '../img/sprite-2x.png';
$sprite-groupes-2x: (240px, 132px, -240px, -132px, 50px, 38px, 308px, 302px, '../img/sprite-2x.png', 'sprite_groupes@2x', );
$sprite-map-2x-name: 'sprite_map@2x';
$sprite-map-2x-x: 0px;
$sprite-map-2x-y: 116px;
$sprite-map-2x-offset-x: 0px;
$sprite-map-2x-offset-y: -116px;
$sprite-map-2x-width: 68px;
$sprite-map-2x-height: 66px;
$sprite-map-2x-total-width: 308px;
$sprite-map-2x-total-height: 302px;
$sprite-map-2x-image: '../img/sprite-2x.png';
$sprite-map-2x: (0px, 116px, 0px, -116px, 68px, 66px, 308px, 302px, '../img/sprite-2x.png', 'sprite_map@2x', );
$sprite-map-hover-bateau-2x-name: 'sprite_map_hover_bateau@2x';
$sprite-map-hover-bateau-2x-x: 68px;
$sprite-map-hover-bateau-2x-y: 116px;
$sprite-map-hover-bateau-2x-offset-x: -68px;
$sprite-map-hover-bateau-2x-offset-y: -116px;
$sprite-map-hover-bateau-2x-width: 68px;
$sprite-map-hover-bateau-2x-height: 66px;
$sprite-map-hover-bateau-2x-total-width: 308px;
$sprite-map-hover-bateau-2x-total-height: 302px;
$sprite-map-hover-bateau-2x-image: '../img/sprite-2x.png';
$sprite-map-hover-bateau-2x: (68px, 116px, -68px, -116px, 68px, 66px, 308px, 302px, '../img/sprite-2x.png', 'sprite_map_hover_bateau@2x', );
$sprite-map-hover-choranche-2x-name: 'sprite_map_hover_choranche@2x';
$sprite-map-hover-choranche-2x-x: 136px;
$sprite-map-hover-choranche-2x-y: 116px;
$sprite-map-hover-choranche-2x-offset-x: -136px;
$sprite-map-hover-choranche-2x-offset-y: -116px;
$sprite-map-hover-choranche-2x-width: 68px;
$sprite-map-hover-choranche-2x-height: 66px;
$sprite-map-hover-choranche-2x-total-width: 308px;
$sprite-map-hover-choranche-2x-total-height: 302px;
$sprite-map-hover-choranche-2x-image: '../img/sprite-2x.png';
$sprite-map-hover-choranche-2x: (136px, 116px, -136px, -116px, 68px, 66px, 308px, 302px, '../img/sprite-2x.png', 'sprite_map_hover_choranche@2x', );
$sprite-map-hover-jardin-2x-name: 'sprite_map_hover_jardin@2x';
$sprite-map-hover-jardin-2x-x: 240px;
$sprite-map-hover-jardin-2x-y: 0px;
$sprite-map-hover-jardin-2x-offset-x: -240px;
$sprite-map-hover-jardin-2x-offset-y: 0px;
$sprite-map-hover-jardin-2x-width: 68px;
$sprite-map-hover-jardin-2x-height: 66px;
$sprite-map-hover-jardin-2x-total-width: 308px;
$sprite-map-hover-jardin-2x-total-height: 302px;
$sprite-map-hover-jardin-2x-image: '../img/sprite-2x.png';
$sprite-map-hover-jardin-2x: (240px, 0px, -240px, 0px, 68px, 66px, 308px, 302px, '../img/sprite-2x.png', 'sprite_map_hover_jardin@2x', );
$sprite-map-hover-thais-2x-name: 'sprite_map_hover_thais@2x';
$sprite-map-hover-thais-2x-x: 240px;
$sprite-map-hover-thais-2x-y: 66px;
$sprite-map-hover-thais-2x-offset-x: -240px;
$sprite-map-hover-thais-2x-offset-y: -66px;
$sprite-map-hover-thais-2x-width: 68px;
$sprite-map-hover-thais-2x-height: 66px;
$sprite-map-hover-thais-2x-total-width: 308px;
$sprite-map-hover-thais-2x-total-height: 302px;
$sprite-map-hover-thais-2x-image: '../img/sprite-2x.png';
$sprite-map-hover-thais-2x: (240px, 66px, -240px, -66px, 68px, 66px, 308px, 302px, '../img/sprite-2x.png', 'sprite_map_hover_thais@2x', );
$sprite-search-2x-name: 'sprite_search@2x';
$sprite-search-2x-x: 204px;
$sprite-search-2x-y: 116px;
$sprite-search-2x-offset-x: -204px;
$sprite-search-2x-offset-y: -116px;
$sprite-search-2x-width: 30px;
$sprite-search-2x-height: 32px;
$sprite-search-2x-total-width: 308px;
$sprite-search-2x-total-height: 302px;
$sprite-search-2x-image: '../img/sprite-2x.png';
$sprite-search-2x: (204px, 116px, -204px, -116px, 30px, 32px, 308px, 302px, '../img/sprite-2x.png', 'sprite_search@2x', );
$sprite-tickets-2x-name: 'sprite_tickets@2x';
$sprite-tickets-2x-x: 0px;
$sprite-tickets-2x-y: 0px;
$sprite-tickets-2x-offset-x: 0px;
$sprite-tickets-2x-offset-y: 0px;
$sprite-tickets-2x-width: 80px;
$sprite-tickets-2x-height: 58px;
$sprite-tickets-2x-total-width: 308px;
$sprite-tickets-2x-total-height: 302px;
$sprite-tickets-2x-image: '../img/sprite-2x.png';
$sprite-tickets-2x: (0px, 0px, 0px, 0px, 80px, 58px, 308px, 302px, '../img/sprite-2x.png', 'sprite_tickets@2x', );
$sprite-tickets-hover-bateau-2x-name: 'sprite_tickets_hover_bateau@2x';
$sprite-tickets-hover-bateau-2x-x: 80px;
$sprite-tickets-hover-bateau-2x-y: 0px;
$sprite-tickets-hover-bateau-2x-offset-x: -80px;
$sprite-tickets-hover-bateau-2x-offset-y: 0px;
$sprite-tickets-hover-bateau-2x-width: 80px;
$sprite-tickets-hover-bateau-2x-height: 58px;
$sprite-tickets-hover-bateau-2x-total-width: 308px;
$sprite-tickets-hover-bateau-2x-total-height: 302px;
$sprite-tickets-hover-bateau-2x-image: '../img/sprite-2x.png';
$sprite-tickets-hover-bateau-2x: (80px, 0px, -80px, 0px, 80px, 58px, 308px, 302px, '../img/sprite-2x.png', 'sprite_tickets_hover_bateau@2x', );
$sprite-tickets-hover-choranche-2x-name: 'sprite_tickets_hover_choranche@2x';
$sprite-tickets-hover-choranche-2x-x: 0px;
$sprite-tickets-hover-choranche-2x-y: 58px;
$sprite-tickets-hover-choranche-2x-offset-x: 0px;
$sprite-tickets-hover-choranche-2x-offset-y: -58px;
$sprite-tickets-hover-choranche-2x-width: 80px;
$sprite-tickets-hover-choranche-2x-height: 58px;
$sprite-tickets-hover-choranche-2x-total-width: 308px;
$sprite-tickets-hover-choranche-2x-total-height: 302px;
$sprite-tickets-hover-choranche-2x-image: '../img/sprite-2x.png';
$sprite-tickets-hover-choranche-2x: (0px, 58px, 0px, -58px, 80px, 58px, 308px, 302px, '../img/sprite-2x.png', 'sprite_tickets_hover_choranche@2x', );
$sprite-tickets-hover-jardin-2x-name: 'sprite_tickets_hover_jardin@2x';
$sprite-tickets-hover-jardin-2x-x: 80px;
$sprite-tickets-hover-jardin-2x-y: 58px;
$sprite-tickets-hover-jardin-2x-offset-x: -80px;
$sprite-tickets-hover-jardin-2x-offset-y: -58px;
$sprite-tickets-hover-jardin-2x-width: 80px;
$sprite-tickets-hover-jardin-2x-height: 58px;
$sprite-tickets-hover-jardin-2x-total-width: 308px;
$sprite-tickets-hover-jardin-2x-total-height: 302px;
$sprite-tickets-hover-jardin-2x-image: '../img/sprite-2x.png';
$sprite-tickets-hover-jardin-2x: (80px, 58px, -80px, -58px, 80px, 58px, 308px, 302px, '../img/sprite-2x.png', 'sprite_tickets_hover_jardin@2x', );
$sprite-tickets-hover-thais-2x-name: 'sprite_tickets_hover_thais@2x';
$sprite-tickets-hover-thais-2x-x: 160px;
$sprite-tickets-hover-thais-2x-y: 0px;
$sprite-tickets-hover-thais-2x-offset-x: -160px;
$sprite-tickets-hover-thais-2x-offset-y: 0px;
$sprite-tickets-hover-thais-2x-width: 80px;
$sprite-tickets-hover-thais-2x-height: 58px;
$sprite-tickets-hover-thais-2x-total-width: 308px;
$sprite-tickets-hover-thais-2x-total-height: 302px;
$sprite-tickets-hover-thais-2x-image: '../img/sprite-2x.png';
$sprite-tickets-hover-thais-2x: (160px, 0px, -160px, 0px, 80px, 58px, 308px, 302px, '../img/sprite-2x.png', 'sprite_tickets_hover_thais@2x', );
$spritesheet-width: 154px;
$spritesheet-height: 151px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($sprite-contact, $sprite-explore, $sprite-explore-hover-bateau, $sprite-explore-hover-choranche, $sprite-explore-hover-jardin, $sprite-explore-hover-thais, $sprite-groupes, $sprite-map, $sprite-map-hover-bateau, $sprite-map-hover-choranche, $sprite-map-hover-jardin, $sprite-map-hover-thais, $sprite-search, $sprite-tickets, $sprite-tickets-hover-bateau, $sprite-tickets-hover-choranche, $sprite-tickets-hover-jardin, $sprite-tickets-hover-thais, );
$spritesheet: (154px, 151px, '../img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 308px;
$retina-spritesheet-height: 302px;
$retina-spritesheet-image: '../img/sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-contact-2x, $sprite-explore-2x, $sprite-explore-hover-bateau-2x, $sprite-explore-hover-choranche-2x, $sprite-explore-hover-jardin-2x, $sprite-explore-hover-thais-2x, $sprite-groupes-2x, $sprite-map-2x, $sprite-map-hover-bateau-2x, $sprite-map-hover-choranche-2x, $sprite-map-hover-jardin-2x, $sprite-map-hover-thais-2x, $sprite-search-2x, $sprite-tickets-2x, $sprite-tickets-hover-bateau-2x, $sprite-tickets-hover-choranche-2x, $sprite-tickets-hover-jardin-2x, $sprite-tickets-hover-thais-2x, );
$retina-spritesheet: (308px, 302px, '../img/sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-contact-group-name: 'sprite_contact';
$sprite-contact-group: ('sprite_contact', $sprite-contact, $sprite-contact-2x, );
$sprite-explore-group-name: 'sprite_explore';
$sprite-explore-group: ('sprite_explore', $sprite-explore, $sprite-explore-2x, );
$sprite-explore-hover-bateau-group-name: 'sprite_explore_hover_bateau';
$sprite-explore-hover-bateau-group: ('sprite_explore_hover_bateau', $sprite-explore-hover-bateau, $sprite-explore-hover-bateau-2x, );
$sprite-explore-hover-choranche-group-name: 'sprite_explore_hover_choranche';
$sprite-explore-hover-choranche-group: ('sprite_explore_hover_choranche', $sprite-explore-hover-choranche, $sprite-explore-hover-choranche-2x, );
$sprite-explore-hover-jardin-group-name: 'sprite_explore_hover_jardin';
$sprite-explore-hover-jardin-group: ('sprite_explore_hover_jardin', $sprite-explore-hover-jardin, $sprite-explore-hover-jardin-2x, );
$sprite-explore-hover-thais-group-name: 'sprite_explore_hover_thais';
$sprite-explore-hover-thais-group: ('sprite_explore_hover_thais', $sprite-explore-hover-thais, $sprite-explore-hover-thais-2x, );
$sprite-groupes-group-name: 'sprite_groupes';
$sprite-groupes-group: ('sprite_groupes', $sprite-groupes, $sprite-groupes-2x, );
$sprite-map-group-name: 'sprite_map';
$sprite-map-group: ('sprite_map', $sprite-map, $sprite-map-2x, );
$sprite-map-hover-bateau-group-name: 'sprite_map_hover_bateau';
$sprite-map-hover-bateau-group: ('sprite_map_hover_bateau', $sprite-map-hover-bateau, $sprite-map-hover-bateau-2x, );
$sprite-map-hover-choranche-group-name: 'sprite_map_hover_choranche';
$sprite-map-hover-choranche-group: ('sprite_map_hover_choranche', $sprite-map-hover-choranche, $sprite-map-hover-choranche-2x, );
$sprite-map-hover-jardin-group-name: 'sprite_map_hover_jardin';
$sprite-map-hover-jardin-group: ('sprite_map_hover_jardin', $sprite-map-hover-jardin, $sprite-map-hover-jardin-2x, );
$sprite-map-hover-thais-group-name: 'sprite_map_hover_thais';
$sprite-map-hover-thais-group: ('sprite_map_hover_thais', $sprite-map-hover-thais, $sprite-map-hover-thais-2x, );
$sprite-search-group-name: 'sprite_search';
$sprite-search-group: ('sprite_search', $sprite-search, $sprite-search-2x, );
$sprite-tickets-group-name: 'sprite_tickets';
$sprite-tickets-group: ('sprite_tickets', $sprite-tickets, $sprite-tickets-2x, );
$sprite-tickets-hover-bateau-group-name: 'sprite_tickets_hover_bateau';
$sprite-tickets-hover-bateau-group: ('sprite_tickets_hover_bateau', $sprite-tickets-hover-bateau, $sprite-tickets-hover-bateau-2x, );
$sprite-tickets-hover-choranche-group-name: 'sprite_tickets_hover_choranche';
$sprite-tickets-hover-choranche-group: ('sprite_tickets_hover_choranche', $sprite-tickets-hover-choranche, $sprite-tickets-hover-choranche-2x, );
$sprite-tickets-hover-jardin-group-name: 'sprite_tickets_hover_jardin';
$sprite-tickets-hover-jardin-group: ('sprite_tickets_hover_jardin', $sprite-tickets-hover-jardin, $sprite-tickets-hover-jardin-2x, );
$sprite-tickets-hover-thais-group-name: 'sprite_tickets_hover_thais';
$sprite-tickets-hover-thais-group: ('sprite_tickets_hover_thais', $sprite-tickets-hover-thais, $sprite-tickets-hover-thais-2x, );
$retina-groups: ($sprite-contact-group, $sprite-explore-group, $sprite-explore-hover-bateau-group, $sprite-explore-hover-choranche-group, $sprite-explore-hover-jardin-group, $sprite-explore-hover-thais-group, $sprite-groupes-group, $sprite-map-group, $sprite-map-hover-bateau-group, $sprite-map-hover-choranche-group, $sprite-map-hover-jardin-group, $sprite-map-hover-thais-group, $sprite-search-group, $sprite-tickets-group, $sprite-tickets-hover-bateau-group, $sprite-tickets-hover-choranche-group, $sprite-tickets-hover-jardin-group, $sprite-tickets-hover-thais-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
